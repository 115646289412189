import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import GreenBox from '../components/Battles/GreenBox.vue';
import ExclusiveBoxDummy from '../components/Battles/ExclusiveBoxDummy.vue';
import BlueBox from '../components/Battles/BlueBox.vue';
import functions from '../components/functions';
import Axios from 'axios';
export default {
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');
    this.zistiFirstEverLoad();
  },
  mixins: [functions],
  components: {
    GreenBox: GreenBox,
    BlueBox: BlueBox,
    ExclusiveBoxDummy: ExclusiveBoxDummy
  },
  computed: {
    availableBattlesList: function availableBattlesList() {
      return this.$store.getters.getAvailableBattles({
        paid: false
      });
    },
    getBattleList: function getBattleList() {
      return this.$store.getters.getBattleList;
    },
    getModalLoading: function getModalLoading() {
      return this.$store.getters.getModalLoading;
    },
    getAllBattlesId: function getAllBattlesId() {
      return this.$store.getters.getBattleList.map(function (el) {
        return el.battleSettings.id;
      });
    },
    getBattleCount: function getBattleCount() {
      return this.$store.getters.getBattleCount;
    },
    pocetActiveBattlov: function pocetActiveBattlov() {
      if ('activeContest' in this.getBattleCount) {
        return parseInt(this.getBattleCount.activeContest);
      }

      return 0;
    },
    existujeExclusive: function existujeExclusive() {
      var pocidaloPaidBattles = this.getBattleList.filter(function (el) {
        var return1,
            return2 = false;
        return1 = (el.battleSettings.toBattleEnd > 0 && el.battleSettings.toBattleStart < 3600 * 24 * 7) === true ? true : false;
        return2 = el.battleSettings.paidBattle === true ? true : false;
        return return1 && return2;
      }).length;

      if (pocidaloPaidBattles > 0) {
        return true;
      }

      return false;
    },
    showButtonLoadBattles: function showButtonLoadBattles() {
      if ('activeContest' in this.getBattleCount) {
        if (this.getBattleCount.activeContest > this.availableBattlesList.length) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    sortedBattleList: function sortedBattleList(battle) {
      return battle.sort(function (a, b) {
        if (a.battleSettings.toVoteStart + a.battleSettings.toBattleEnd < b.battleSettings.toVoteStart + b.battleSettings.toBattleEnd) {
          return 1;
        }

        if (a.battleSettings.toVoteStart + a.battleSettings.toBattleEnd > b.battleSettings.toVoteStart + b.battleSettings.toBattleEnd) {
          return -1;
        }

        return 0;
      });
    },
    loadActiveBattles: function loadActiveBattles() {
      var _this = this;

      this.$store.commit('setModalLoading', true);
      var udaje = {
        'battles': JSON.stringify(this.getAllBattlesId),
        'type': 'active'
      };
      Axios.post(this.$store.getters.URLAJAX + '/handler.php?page=load-more-battles', udaje).then(function (response) {
        var res = response.data;

        if (res.return) {
          if ('battleCount' in res) {
            //-----------------zapiseme pocty contestov
            _this.$store.commit('setBattleCount', res.battleCount);
          }

          if (res.battleList.length == 0) {
            _this.showButtonLoadBattles = false;
          } else {
            _this.$store.dispatch('addToBattles', res.battleList);
          }
        } else {
          _this.$store.commit('setErrorModal', {
            visible: true,
            data: res.returnMsg
          });
        }
      }).catch(function (e) {
        _this.$store.commit('setErrorModal', {
          visible: true,
          data: "#161 Network error" + e + ""
        });
      }).finally(function () {
        _this.$store.commit('setModalLoading', false);
      });
    },
    zistiFirstEverLoad: function zistiFirstEverLoad() {
      if (!localStorage.getItem('CFactiveContests')) {
        //--------ak otvara okno s tym ze sa otvara este top100 alebo nejake konkretne vysledky nespravi nic
        if (!this.$route.params.action) {
          this.$store.commit('setUpperModal', {
            visible: true,
            whatToShow: 'FirstEverLoadActiveContests',
            data: {}
          });
          localStorage.setItem('CFactiveContests', JSON.stringify(true));
        }
      } else {
        this.$store.commit('setFirstEverLoad', {
          activeContests: true
        });
      }
    },
    openHowToPlayModal: function openHowToPlayModal() {
      this.$store.commit('setUpperModal', {
        visible: true,
        whatToShow: 'HowToPlay',
        data: {}
      });
    }
  }
};