<template>
  <div class="bg-black bg-opacity-75 border-b-4 border-l border-orange-our border-r border-solid border-t cursor-pointer flex flex-col max-w-lg mt-8 shadow-glowOrange shadow-md w-full">
    <figure class="w-full block relative" @click="openExclusivePage()">
      <img class="block w-full h-48 object-cover object-center" :src="`${$store.getters.URLSITE}/pictures/exclusiveContest.jpg`" alt="Exclusive contests">
      <figcaption class="-translate-x-2/4 absolute bg-black bg-opacity-60 font-bold left-2/4 py-2 text-2xl text-center top-0 transform w-4/5">TRY OUR PAID CONTESTS</figcaption>
    </figure>
    <div class="flex items-stretch justify-center relative select-none" @click="openExclusivePage()">
      <div class="bg-opacity-30 boxTimeRemainClipStart boxTimeRemainGreen flex-grow mr-2 pl-6 pr-0 py-2">UPLOAD</div>
      <div class="-ml-3 bg-blue-our boxTimeRemainBlue boxTimeRemainClipMiddle flex-grow leading-6 mr-2 pl-6 pr-0 py-2">VOTE</div>
      <div class="-ml-3 bg-pink-700 boxTimeRemainClipEnd boxTimeRemainPink flex-grow leading-6 pl-8 pr-4 py-2">RESULTS</div>
    </div>
    <div class="flex-grow" @click="openExclusivePage()">
      <div class="text-2xl text-white text-center p-5">Use your credits to enter and win prizes!</div>
      <div class="text-2xl text-white text-center p-5">Results in paid competitions have <br> <span class="bg-yellow-400 font-bold leading-10 px-2 py-1 text-black underline">NO impact</span> <br> on the scoreboard.</div>
    </div>
    <div class="bg-opacity-50 bg-orange-our flex h-9 justify-between p-2" @click="openFaqPaidContests()">
      <span class="text-sm cursor-pointer text-white">More information about paid contests</span>
    </div>
  </div>
</template>

<script>
// >7DNI+ - SOON
// >2DNI+ - DNI
// <2DNI - HOURS
// <1DNI - HOUR MIN SEK
export default {
  methods:{
    openExclusivePage(){
      this.$router.push({path:'/exclusive-contests'});
    },
    openFaqPaidContests(){
      this.$router.push({path:'/faq',query: { search: 'Paid competitions' }});
    },
  },
}
</script>
